/* Content */
.content {
  display: flex;
  flex-direction: column;
  line-height: 2;
}

.walletAddress {
  width: 80%;
}

.amount {
  width: 93%;
  padding: 0 20px 0 0;
  margin-bottom: 10px;
  height: 27px;
}

.boldText {
  font-weight: bold;
  font-size: 18px;
}

.success {
  background-color: green;
  color: #fff;
  display: none;
  text-align: center;
}

.success > a {
  color: #fff;
  padding: 5px 10px;
}

.failed {
  background-color: red;
  color: #fff;
  text-align: center;
  display: none;
}

.failed > a {
  color: #fff;
  padding: 5px 10px;
}

.btns {
  display: flex;
  justify-content: space-between;
}

.btn {
  color: #000;
  min-width: 80px;
  text-align: center;
}

.btn:hover {
  background-color: #000;
  color: #fff;
}
