.header {
  display: flex;
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 40px;
  justify-content: space-around;
  align-items: center;
  background-color: #F2F2FB;
}

.logo {
  max-width: 40px;
}

.links {
  text-decoration: none;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: #888;
  padding: 5px 10px;
  border: solid 1px #888;
}

.links:hover {
  background-color: #000;
  color: #fff;
}

.btn {
  border: solid 1px #888;
  padding: 5px 10px;
  font-weight: 600;
  text-decoration: none;
}
