.messageBoard {
  position: fixed;
  /* Sit on top of the page content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  font-size: xx-large;
  color: #fff;
}

.messageBoard > p {
  text-align: center;
}

.messageBoard > a {
  text-decoration: none;
  color: #fff;
  border: solid 1px #fff;
  padding: 5px 20px;
}

.messageBoard > a:hover {
  background-color: #fff;
  color: #000;
  cursor: pointer;
}
