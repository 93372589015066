.footer {
  display: flex;
  justify-content: space-around;
  height: 120px;
  bottom: 0;
  line-height: 1.6;
  padding: 10px 0;
  background-color: #f2f2fb;
  bottom: 0;
  right: 0;
  width: 100%;
}

.footer > ul,
li,
p {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer > ul > li > a,
.connect > ul > li > a {
  text-decoration: none;
  color: #000;
}

.footer > ul > p,
.connect > p {
  font-size: 26px;
  font-weight: 700;
}

.footer > img {
  width: 194px;
}

.connect > p {
  margin: 0;
}

.connect > ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.connect > ul > li {
  float: left;
  width: 40px;
  font-size: 24px;
}
