.poolInfor {
  width: 53%;
  margin: 0;
  list-style: none;
  background-color: #ccc;
  border-radius: 15px;
}

.poolInfor > ul {
  list-style: none;
  line-height: 2;
  padding: 20px 40px;
  margin: 0;
}
